const jsCookie = require('js-cookie');

var Engine;

$(function () {

    document.querySelectorAll('.hide-before-load-js').forEach((el) => {
        el.classList.remove('hide-before-load-js');
    });

    Engine = {
        circle: {
            init: function () {
                Engine.circle.make(".task__list");
            },

            make: function (elem, radius) {

                var $root = $(elem);
                var $elements = $root.find('li');

                var radius = '210px';
                var start = -90;
                var numberOfElements = $elements.length;
                var slice = 360 / numberOfElements;

                $elements.each(function (i) {
                    var $self = $(this);
                    var rotate = slice * i + start;
                    var rotateReverse = rotate * -1;

                    $self.css({
                        'transform': 'rotate(' + rotate + 'deg) translate(' + radius + ') rotate(' + rotateReverse + 'deg)'
                    });
                });
            }
        },

        filter: function () {
            if (parseInt(jsCookie.get('tooltipDismissed')) !== 1) {
                $(".filter__tip").removeClass("hidden");
            }

            $(".filter__tip .btn").on("click", function () {
                $(".filter__tip").addClass("hidden");
                jsCookie.set('tooltipDismissed', 1, {
                    expires: 365
                });
                return false;
            });
        },

        result: function () {
            $(".result-more a").on("click", function () {
                $(this).closest(".result-list").addClass("result-list--active");
                return false;
            });
        },

        scrolltop: function () {
            $(".foot__top").on("click", function () {
                $("html, body").stop().animate({
                    scrollTop: 0
                }, 500, 'swing');
            });
        },

        local: function () {
            $(".local").on("click", function () {
                var id = $(this).attr("href");
                var $target = $(id);
                var offset = 0;
                if ($target.length > 0) {

                    if ($target.hasClass("section--shift")) {
                        offset = -30;
                    }

                    $.scrollTo(id, 400, {
                        offset: offset
                    });
                    return false;
                }
            });
        },

        section: function () {
            var sections = $('.section--wp').waypoint(function (dir) {
                $(this.element).addClass("section--active");
            }, {
                offset: "75%"
            });
        },

        slider: function () {
            function initCarousel(root) {
                var $root = $(root);

                var $rootText = $root.find(".slider__text");
                var $rootImgs = $root.find(".slider__imgs");
                var $rootStatus = $root.find(".slider__status");

                var $carouselText = $rootText.flickity({
                    prevNextButtons: false,
                    pageDots: false,
                    draggable: false,
                });

                var $carouselImgs = $rootImgs.flickity({
                    sync: root + ' .slider__text',
                    pageDots: false,
                    wrapAround: true
                });

                var flkty = $carouselImgs.data('flickity');

                function updateStatus() {
                    var cellNumber = flkty.selectedIndex + 1;
                    $rootStatus.text(cellNumber + '/' + flkty.slides.length);
                }

                if ($rootStatus.length > 0) {
                    updateStatus();
                    $carouselImgs.on('change.flickity', updateStatus);
                }
            }

            initCarousel(".slider--home");
            initCarousel(".slider--practice");
            initCarousel(".slider--publications");
            initCarousel(".slider--graduation");

            const calculateHeight = () => {
                let maxHeight = 0;
                let maxHeightCarousel = 0;
                let maxHeightNews = 0;

                document.querySelectorAll('.hpslider .carousel-item').forEach((el) => {
                        if (el.clientHeight > maxHeight) {
                            maxHeight = el.clientHeight;
                        }
                    }
                );

                document.querySelectorAll('.hpslider .carousel-item').forEach((el) => {
                        el.style.height = maxHeight + "px";
                    }
                );

                document.querySelectorAll('.testimonials-card').forEach((el) => {
                        if (el.clientHeight > maxHeightCarousel) {
                            maxHeightCarousel = el.clientHeight;
                        }
                    }
                );

                document.querySelectorAll('.testimonials-card').forEach((el) => {
                        el.style.height = maxHeightCarousel + "px";
                    }
                );

                $(".news .card__image").height($(".news .card__image").width() * 3 / 4);
            };

            const resetHeight = () => {
                document.querySelectorAll('.hpslider .carousel-item').forEach((el) => {
                        el.style.height = "auto";
                    }
                );

                document.querySelectorAll('.testimonials-card').forEach((el) => {
                        el.style.height = "auto";
                    }
                );
            };

            const isElementInViewport = (el) => {
                var rect = el.getBoundingClientRect();

                return (
                    rect.left >= 0 &&
                    (rect.right + 130) <= (window.innerWidth || document.documentElement.clientWidth)
                );
            };

            let testimonials = $(".testimonials-slider").flickity({
                prevNextButtons: false,
                pageDots: false,
                draggable: true,
                cellAlign: 'left',
                wrapAround: false,
                contain: true
            });

            const testimonialsVisible = (allowRemoveNextDisabled = true) => {
                let allIsVisible = (window.innerWidth || document.documentElement.clientWidth) > 2000;

                if (allIsVisible) {
                    $("#testimonials-previous").addClass("disabled");
                }

                if (allIsVisible) {
                    $("#testimonials-next").addClass("disabled");
                } else if (allowRemoveNextDisabled) {
                    $("#testimonials-next").removeClass("disabled");
                    testimonials.flickity('select', 0);
                }
            };

            calculateHeight();
            testimonialsVisible();

            $(window).resize(() => {
                resetHeight();
                calculateHeight();
                testimonialsVisible();
            });

            $(".hpslider").flickity({
                prevNextButtons: true,
                pageDots: true,
                draggable: true,
                wrapAround: true,
                contain: true
            });

            $(".projectslider").flickity({
                prevNextButtons: true,
                pageDots: true,
                draggable: true,
                wrapAround: true,
                contain: true,
                autoPlay: true
            });

            $(".projectpartnerslider").flickity({
                prevNextButtons: false,
                pageDots: false,
                draggable: true,
                wrapAround: true,
                contain: true,
                autoPlay: true
            });

            $(".product-preview").flickity({
                prevNextButtons: false,
                pageDots: true,
                draggable: true,
                wrapAround: true,
                contain: true
            });

            $(".product-review").flickity({
                prevNextButtons: false,
                pageDots: true,
                draggable: true,
                wrapAround: true,
                contain: true
            });

            document.querySelectorAll('.premium-tab-selector .nav-link').forEach((tabEl) => {
                tabEl.addEventListener('shown.bs.tab', event => {
                    window.location.hash = event.target.dataset.bsTarget.replace("#", "#tab-");
                    window.postMessage({type: "premiumTabChange", tab: event.target.dataset.bsTarget.replace("#", "")});
                    window.setPremiumTab = event.target.dataset.bsTarget.replace("#", "");

                    if ($(".product-preview").html()) {
                        $(".product-preview").flickity('resize');
                    }
                    if ($(".product-review").html()) {
                        $(".product-review").flickity('resize');
                    }
                })
            });

            const premiumSelector = document.querySelector('.premium-tab-selector');

            if (premiumSelector && window.location.hash.indexOf("#tab-") !== -1) {
                $(".premium-tab-selector .nav-link[data-bs-target='" + window.location.hash.replace("#tab-", "#") + "']").click();
            } else if (premiumSelector && defaultTab) {
                $(".premium-tab-selector .nav-link[data-bs-target='#" + defaultTab + "']").click();
            }


            $(".merch-preview").flickity({
                cellAlign: 'center',
                accessibility: false,
                prevNextButtons: true,
                pageDots: true,
                draggable: true,
                wrapAround: 'true',
                contain: 'true',
                imagesLoaded: true,
            });

            $(".offcanvas-merch").on('shown.bs.offcanvas', function (event, index) {
                setTimeout(() => {
                    $(".merch-preview").flickity('resize');
                }, 500);
            });

            $("#testimonials-previous").addClass("disabled");

            testimonials.on('select.flickity', function (event, index) {
                $("#testimonials-previous").addClass("disabled");
                $("#testimonials-next").addClass("disabled");

                if (index > 0) {
                    $("#testimonials-previous").removeClass("disabled");
                }

                if (index < 3) {
                    $("#testimonials-next").removeClass("disabled");
                }

                testimonialsVisible(false);
            });


            $("#testimonials-previous").click(() => testimonials.flickity('previous'));
            $("#testimonials-next").click(() => testimonials.flickity('next'));

            if (document.querySelector('.homepage-book-slider .slides')) {
                $('.homepage-book-slider .slides').slick({
                    infinite: true,
                    arrows: false,
                    autoplay: true,
                    fade: true
                });
            }
        },

        init: function () {
            Engine.filter();
            Engine.result();
            Engine.scrolltop();
            Engine.local();
            Engine.section();
            Engine.slider();
            Engine.circle.init();
        }
    };

    Engine.init();
});

window.collapseFilter = () => {
    $("#rocnik").collapse('hide');
    $("#tema").collapse('hide');
    $("#podtema").collapse('hide');
}

window.collapseFilterMobile = () => {
    $.fancybox.close();
}

document.addEventListener('show.bs.collapse', function (event) {
    if (event.target.id != 'navbar') {
        return;
    }

    $(".navbar").addClass("child-collapse");
});

document.addEventListener('hidden.bs.collapse', function (event) {
    if (event.target.id != 'navbar') {
        return;
    }

    $(".navbar").removeClass("child-collapse");
});